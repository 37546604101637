import { Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';

import MentionBadgeNode from './MentionBadgeNode';

export default Node.create({
  name: 'mentionBadge',
  group: 'inline',
  inline: true,
  draggable: true,

  addAttributes() {
    return {
      id: { default: null },
      type: { default: null },
    };
  },

  renderHTML({ HTMLAttributes }) {
    return ['span', HTMLAttributes];
  },

  addNodeView() {
    return ReactNodeViewRenderer(MentionBadgeNode);
  },
});
