import { NodeToolbar, Position, useNodeId } from '@xyflow/react';
import React from 'react';

import { useFlowchart } from '../../../../../../contexts/FlowchartControlProvider';
import ConnectionsFlyout from './ConnectionsFlyout';

const ConnectionsIconToolbar = () => {
  const nodeId = useNodeId();
  const {
    flowchartHandlers: { getNodeConnections },
  } = useFlowchart();
  const nodeConnections = getNodeConnections(nodeId);

  return (
    <>
      {nodeConnections.length > 0 && (
        <NodeToolbar isVisible offset={-15} position={Position.Bottom}>
          <ConnectionsFlyout connections={nodeConnections} iconsButton />
        </NodeToolbar>
      )}
    </>
  );
};

export default ConnectionsIconToolbar;
