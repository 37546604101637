import { JSONContent } from '@tiptap/core';
import CharacterCount from '@tiptap/extension-character-count';
import Color from '@tiptap/extension-color';
import Link from '@tiptap/extension-link';
import Mention from '@tiptap/extension-mention';
import Placeholder from '@tiptap/extension-placeholder';
import Underline from '@tiptap/extension-underline';
import { useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { useCallback, useEffect } from 'react';
import { useTheme } from 'styled-components';

import { CustomTextStyle } from '../../../../components/application/editor/tiptap_extensions/paragraph/textStyle';
import { useFlowchart } from '../../../../contexts/FlowchartControlProvider';
import initTranslations from '../../../../lib/initTranslations';
import suggestion from '../tiptap_extensions/connection_suggestions/suggestion';
import MentionBadge from '../tiptap_extensions/mention_badge';

const t = initTranslations('curriculums_view.flowchart');

type Props = {
  nodeId: string;
  handleUpdate: (content: JSONContent) => void;
  selected: boolean;
};

export const useShapeEditor = ({ nodeId, handleUpdate, selected }: Props) => {
  const {
    flowchartHandlers: { getNode, setNodes },
  } = useFlowchart();
  const node = getNode(nodeId);
  const { palettes } = useTheme();

  // TODO: This is a temporary solution to prevent the editor from being editable when not selected
  const editable = selected;

  const setNodeIsEditing = useCallback(
    (value: boolean) => {
      if (!node || node.data.isEditing === value) {
        return;
      }

      setNodes((nodes) =>
        nodes.map((node) => {
          return node.id === nodeId ? { ...node, data: { ...node.data, isEditing: value } } : node;
        })
      );
    },
    [nodeId, node, setNodes]
  );

  const onUpdate = useCallback(({ editor }) => handleUpdate(editor.getJSON()), [handleUpdate]);

  const onBlur = useCallback(() => {
    setNodeIsEditing(false);
  }, [setNodeIsEditing]);

  const onFocus = useCallback(() => {
    setNodeIsEditing(true);
  }, [setNodeIsEditing]);

  const editor = useEditor(
    {
      content: node?.data.label,
      extensions: [
        StarterKit.configure({
          listItem: false,
          orderedList: false,
          bulletList: false,
          blockquote: false,
        }),
        CustomTextStyle.configure({
          palettes,
        }),
        Underline,
        CharacterCount.configure({
          limit: 1000,
        }),
        Color,
        Mention.configure({
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          suggestion,
        }),
        Placeholder.configure({
          placeholder: () => {
            if (node?.data?.type === 'text') {
              return t('shapes.editor_placeholder.text');
            }
            return t('shapes.editor_placeholder.shape');
          },
          includeChildren: true,
          showOnlyWhenEditable: node?.data?.type === 'text' ? false : true,
        }),
        MentionBadge,
        Link.configure({
          openOnClick: false,
          autolink: true,
        }),
      ],
      onUpdate,
      onFocus,
      onBlur,
      editable,
    },
    [selected, node?.data?.type]
  );

  useEffect(() => {
    if (!node || !editor) {
      return;
    }
    if (node.data.isEditing && !editor.isFocused) {
      editor.commands.focus('end');
    } else if (!node.data.isEditing && editor.isFocused) {
      editor.commands.blur();
    }
  }, [node, editor]);

  return { editor };
};
