import React from 'react';
import styled, { useTheme } from 'styled-components';

import { SkeletonLoader } from '../../../../shared/SkeletonLoader/styles';

const SkeletonRowWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
  padding: ${({ theme: { constants } }) => constants.spacerSm3};
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusLg};
`;

const SkeletonTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
`;

const ConnectionRowSkeletonLoader = () => {
  const {
    constants: { borderRadiusCircle, height2xs, heightLg },
  } = useTheme();

  return (
    <SkeletonRowWrapper>
      <SkeletonLoader borderRadius={borderRadiusCircle} height={heightLg} width='2.5rem' />
      <SkeletonTextWrapper>
        <SkeletonLoader height={height2xs} width='8.875rem' />
        <SkeletonLoader height='0.75rem' width='5.375rem' />
      </SkeletonTextWrapper>
    </SkeletonRowWrapper>
  );
};

export default ConnectionRowSkeletonLoader;
