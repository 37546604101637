import React from 'react';

import { ShapeProps } from '../index';

const Rectangle = ({ width, height, ...svgAttributes }: ShapeProps) => {
  const borderRadius = 2;

  return <rect height={height} rx={borderRadius} width={width} x={0} y={0} {...svgAttributes} />;
};

export default Rectangle;
