import { Edge, Node } from '@xyflow/react';

import { SerializedEdge, SerializedNode } from '../../../../types/Flowchart';

const serializedEdgeKeys: (keyof SerializedEdge)[] = [
  'id',
  'type',
  'source',
  'target',
  'style',
  'sourceHandle',
  'targetHandle',
  'hidden',
  'animated',
  'markerStart',
  'markerEnd',
  'zIndex',
  'interactionWidth',
  'ariaLabel',
];

const serializedEdgeDataKeys: (keyof SerializedEdge['data'])[] = ['label'];

const serializedNodeKeys: (keyof SerializedNode)[] = [
  'id',
  'position',
  'type',
  'sourcePosition',
  'targetPosition',
  'hidden',
  'style',
  'width',
  'height',
  'parentId',
  'zIndex',
  'extent',
  'expandParent',
  'ariaLabel',
];

const serializedNodeDataKeys: (keyof SerializedNode['data'])[] = [
  'type',
  'label',
  'fontSize',
  'connections',
];

const serializeObject = <
  T extends Node | Edge,
  R = T extends Node ? SerializedNode : SerializedEdge
>(
  object: T,
  keys: (keyof T)[],
  dataKeys: string[]
): R => {
  const result: Partial<T> = {};
  result.data = {};
  for (const key of keys) {
    if (key in object) {
      const value = typeof object[key] === 'object' ? Object.assign({}, object[key]) : object[key];

      result[key] = value;
    }
  }

  for (const key of dataKeys) {
    if (object.data && key in object.data) {
      result.data[key] = object.data[key];
    }
  }
  return result as R;
};

const serializeObjects = <
  T extends Node | Edge,
  R = T extends Node ? SerializedNode : SerializedEdge
>(
  objects: T[],
  keys: (keyof T)[],
  dataKeys: string[]
): R[] => {
  const serialized: R[] = new Array(objects.length);

  for (let i = 0; i < objects.length; i++) {
    serialized[i] = serializeObject(objects[i], keys, dataKeys);
  }
  return serialized;
};

export const serializeEdges = <T extends Edge>(edges: T[]) => {
  return serializeObjects(edges, serializedEdgeKeys, serializedEdgeDataKeys);
};

export const serializeNodes = <T extends Node>(nodes: T[]) => {
  return serializeObjects(nodes, serializedNodeKeys, serializedNodeDataKeys);
};
