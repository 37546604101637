import React from 'react';
import styled from 'styled-components';

import initTranslations from '../../../../../lib/initTranslations';
import { SearchPaginatedResult } from '../../../../../redux/services/resourceApis/searches/types';
import { ConnectionData } from '../../../../../types/Flowchart';
import { fontSm5 } from '../../../../styled/TypeSystem';
import { getConnectionFromSearchResult } from '../../lib/getConnectionFromSearchResult';
import ConnectionRow from '../../shared/connections/ConnectionRow/ConnectionRow';
import ConnectionRowSkeletonLoader from '../../shared/connections/ConnectionRow/ConnectionRowSkeletonLoader';

const t = initTranslations('curriculums_view.flowchart.connections.mentions_flyout');

const NoResultsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme: { vars } }) => vars.textSubdued};
  padding: ${({ theme: { constants } }) => constants.spacerMd3};
  ${fontSm5};
`;

type Props = {
  items: SearchPaginatedResult[];
  itemsRef: React.MutableRefObject<Map<number, HTMLElement>>;
  selectedIndex: number;
  isLoading: boolean;
  resultOnClick: (connection: ConnectionData) => void;
};

const SearchResults = ({ items, itemsRef, selectedIndex, isLoading, resultOnClick }: Props) => {
  if (isLoading) {
    return (
      <>
        <ConnectionRowSkeletonLoader />
        <ConnectionRowSkeletonLoader />
      </>
    );
  }

  if (items.length === 0) {
    return <NoResultsWrapper>{t('no_results')}</NoResultsWrapper>;
  }

  return (
    <>
      {items.map((item, index) => {
        const connectionData = getConnectionFromSearchResult(item);

        return (
          <div
            key={`${item.type}-${item.id}`}
            ref={(node) => {
              const map = itemsRef.current;
              if (node) {
                map.set(index, node);
              } else {
                map.delete(index);
              }
            }}
          >
            <ConnectionRow
              className={index === selectedIndex ? 'selected' : ''}
              connection={connectionData}
              onClick={resultOnClick}
            />
          </div>
        );
      })}
    </>
  );
};

export default SearchResults;
