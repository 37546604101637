import { ConnectionData, NodeConnectionType } from '../../../../types/Flowchart';

export type FlowchartState = {
  connectionsData:
    | { [key in NodeConnectionType]: { [key: number]: ConnectionData } }
    | Record<string, never>;
};

type FlowchartAction = {
  type: 'storeConnectionsData';
  connectionsData: ConnectionData[];
};

// Reducer to handle adding or removing detailed flowchart connection data to the state for easy lookup.
// As an example - connectionsData state structure will look like this for a flowchart with course, user and group connections:
// {
//    'Course': {1: {id: 1, type: 'Course', title: 'How to cook", ...}},
//    'User': {4: {id: 4, type: 'User', name: 'Bob Belcher', ...}, 10: {id: 10, type: 'User', name: 'Linda Belcher' ...}},
//    'Group': {2: {id: 2, type: 'Group', name: 'Chefs', ...}}
// }
export const reducer = (state: FlowchartState, action: FlowchartAction): FlowchartState => {
  switch (action.type) {
    case 'storeConnectionsData': {
      const newConnectionsData = { ...state.connectionsData };

      action.connectionsData.map((connectionData) => {
        const { type, id } = connectionData;
        if (!newConnectionsData[type]) newConnectionsData[type] = {};

        newConnectionsData[type][id] = connectionData;
      });
      return { connectionsData: newConnectionsData };
    }
  }
};
