import { NodeToolbar, useNodeId } from '@xyflow/react';
import React from 'react';
import styled from 'styled-components';

import { useFlowchart } from '../../../../../../contexts/FlowchartControlProvider';
import initTranslations from '../../../../../../lib/initTranslations';
import { shapeTypes } from '../../../../../../types/Flowchart';
import { fontMd1 } from '../../../../../styled/TypeSystem';
import ToolbarShapeItem from './ToolbarShapeItem';

const t = initTranslations('curriculums_view.flowchart.shape_toolbar.change_shape');

const StyledShapeNodeToolbar = styled(NodeToolbar)`
  max-width: 12.125rem;
  padding: ${({ theme: { constants } }) => constants.spacerMd2};
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
  border: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface2}`};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusLg};
  box-shadow: ${({ theme: { vars } }) => vars.shadowTopMedium};
`;

const ChangeShapeToolbarTitleWrapper = styled.div`
  padding-bottom: ${({ theme: { constants } }) => constants.spacerMd1};
  font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
  ${fontMd1}
`;

const ShapeItemsWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: start;
  gap: ${({ theme: { constants } }) => constants.spacerSm1};
`;

type ChangeShapeToolbarProps = {
  onClose: () => void;
};
const ChangeShapeToolbar = ({ onClose }: ChangeShapeToolbarProps) => {
  const nodeId = useNodeId();
  const {
    flowchartHandlers: { getNode },
  } = useFlowchart();

  const node = nodeId ? getNode(nodeId) : null;
  const activeNodeType = node?.data?.type;

  return (
    <StyledShapeNodeToolbar offset={-90}>
      <ChangeShapeToolbarTitleWrapper>{t('title')}</ChangeShapeToolbarTitleWrapper>
      <ShapeItemsWrapper>
        {shapeTypes
          .filter((type) => type !== 'text')
          .map((type) => (
            <ToolbarShapeItem
              active={type == activeNodeType}
              key={type}
              onClose={onClose}
              type={type}
            />
          ))}
      </ShapeItemsWrapper>
    </StyledShapeNodeToolbar>
  );
};
export default ChangeShapeToolbar;
