import React from 'react';

import { ShapeProps } from '../index';

const Triangle = ({ width, height, ...svgAttributes }: ShapeProps) => {
  const radius = 2;
  const angle = Math.atan(height / (width / 2));
  const angleX = radius * Math.cos(angle);
  const angleY = radius * Math.sin(angle);

  return (
    <path
      d={`M ${radius} ${height} Q 0 ${height}, ${angleX} ${height - angleY} L ${
        width / 2 - angleX
      } ${angleY} Q ${width / 2} 0, ${width / 2 + angleX} ${angleY} L ${width - angleX} ${
        height - angleY
      } Q ${width} ${height}, ${width - radius} ${height} L ${radius} ${height} Z`}
      {...svgAttributes}
    />
  );
};

export default Triangle;
