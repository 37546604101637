import { NodeViewWrapper } from '@tiptap/react';
import React from 'react';

import { useFlowchart } from '../../../../../contexts/FlowchartControlProvider';
import { NodeConnection } from '../../../../../types/Flowchart';
import MentionBadge from '../../../../design_system/display/MentionBadge/MentionBadge';
import { useGetConnectionRenderData } from '../../hooks/useGetConnectionRenderData';

type Props = {
  node: { attrs: NodeConnection };
};

const MentionBadgeNode = ({ node }: Props) => {
  const { id, type } = node.attrs;
  const {
    flowchartHandlers: { connectionsData },
  } = useFlowchart();

  const connectionData = connectionsData[type] && connectionsData[type][id];

  const deleted = !connectionData;
  const renderDataArgs = deleted
    ? { connection: { type, id }, deleted }
    : { connection: connectionData, deleted };
  const { mentionBadge, route } = useGetConnectionRenderData(renderDataArgs);

  return (
    <NodeViewWrapper as='span' data-drag-handle>
      <MentionBadge disabled={deleted} route={route} {...mentionBadge} />
    </NodeViewWrapper>
  );
};

export default MentionBadgeNode;
