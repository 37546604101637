import styled from 'styled-components';

export const FlowchartLayout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1000;
`;

export const Canvas = styled.div`
  flex-grow: 1;
  width: 100%;
  background-color: ${({ theme: { vars } }) => vars.foundationBase1};
`;
