const ZOOM_LEVELS = [
  // as a percent
  1, 2, 3, 5, 10, 15, 20, 33, 50, 75, 100, 125, 150, 200, 250, 300, 400,
].map((z) => z / 100);

export const MIN_ZOOM = ZOOM_LEVELS[0];
export const MAX_ZOOM = ZOOM_LEVELS[ZOOM_LEVELS.length - 1];

export const nextZoomLevel = (currentZoom: number) => {
  return ZOOM_LEVELS.find((level) => level > currentZoom) ?? MAX_ZOOM;
};

export const prevZoomLevel = (currentZoom: number) => {
  return ZOOM_LEVELS[ZOOM_LEVELS.findIndex((level) => level >= currentZoom) - 1] ?? MIN_ZOOM;
};
