import React, { useRef, useState } from 'react';
import styled from 'styled-components';

import initTranslations from '../../../../../../../lib/initTranslations';
import SearchField from '../../../../../../design_system/Triage/fields/SearchField';
import ConnectionsSearchResults from './ConnectionsSearchResults';

const SearchFieldWrapper = styled.div`
  position: relative;
`;
const StyledSearchField = styled(SearchField)`
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
`;

const t = initTranslations('curriculums_view.flowchart.shape_toolbar.connections.flyout.search');

const ConnectionsSearch = () => {
  const [searchValue, setSearchValue] = useState('');
  const searchFieldRef = useRef<HTMLInputElement>(null);

  const onClick = () => {
    setSearchValue('');
    if (searchFieldRef.current) searchFieldRef.current.focus();
  };

  return (
    <SearchFieldWrapper>
      <StyledSearchField
        autoFocus
        placeholder={t('placeholder')}
        ref={searchFieldRef}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
      />
      {searchValue.length > 2 && (
        <ConnectionsSearchResults onSearchResultClick={onClick} searchValue={searchValue} />
      )}
    </SearchFieldWrapper>
  );
};

export default ConnectionsSearch;
