import { Editor } from '@tiptap/core';
import { ReactRenderer } from '@tiptap/react';
import tippy, { GetReferenceClientRect, Instance } from 'tippy.js';

import ConnectionsList, { ConnectionsListHandle } from './ConnectionsList';

interface SuggestionProps {
  editor: Editor;
  clientRect: GetReferenceClientRect;
  query: string;
}

export default {
  allowSpaces: true,
  items: () => {
    // We are handling the query within the ConnectionsList component - the empty array returned here is not used
    return [];
  },

  render: () => {
    let component: ReactRenderer<ConnectionsListHandle, SuggestionProps>;
    let popup: Instance[];

    return {
      onStart: (props: SuggestionProps) => {
        component = new ReactRenderer(ConnectionsList, { props, editor: props.editor });

        popup = tippy('body', {
          getReferenceClientRect: props.clientRect,
          appendTo: () => document.body,
          content: component.element,
          showOnCreate: true,
          interactive: true,
          trigger: 'manual',
          placement: 'bottom-start',
        });
      },

      onUpdate: (props: SuggestionProps) => {
        component.updateProps(props);

        popup[0].setProps({
          getReferenceClientRect: props.clientRect,
        });
      },

      onKeyDown: (props: { event: KeyboardEvent }) => {
        if (props.event.key === 'Escape') {
          popup[0].hide();
          component.destroy();

          return true;
        }

        return component.ref?.onKeyDown(props);
      },

      onExit: () => {
        popup[0].destroy();
        component.destroy();
      },
    };
  },
};
