import React from 'react';
import styled from 'styled-components';

import ContentSvg from '../../../../../../images/flowchart/content.svg';
import GroupsSvg from '../../../../../../images/flowchart/group.svg';
import PersonSvg from '../../../../../../images/flowchart/person.svg';
import initTranslations from '../../../../../../lib/initTranslations';
import { NodeConnection } from '../../../../../../types/Flowchart';
import { sharedToolbarButtonStyles } from '../../../../editor/toolbar/buttons/components/ToolbarButton';

const StyledButton = styled.button`
  height: ${({ theme: { constants } }) => constants.heightSm};
  cursor: pointer;
  padding-inline: ${({ theme: { constants } }) => constants.spacerSm3};
  padding-top: ${({ theme: { constants } }) => constants.spacerSm2};
  border: ${({
    theme: {
      constants: { borderWidthSm },
      vars: { borderSurface2 },
    },
  }) => `${borderWidthSm} solid ${borderSurface2}`};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusXl};
  ${sharedToolbarButtonStyles};
`;

type ConnectionsIconButtonProps = {
  active: boolean;
  connections: NodeConnection[];
};

const getShowIcons = (connections: NodeConnection[]) => {
  return connections.reduce(
    (showIcons, { type }) => {
      if (type === 'User') {
        showIcons.showUserIcon = true;
      } else if (type === 'Group') {
        showIcons.showGroupIcon = true;
      } else {
        showIcons.showContentIcon = true;
      }

      return showIcons;
    },
    { showContentIcon: false, showUserIcon: false, showGroupIcon: false }
  );
};

const t = initTranslations('curriculums_view.flowchart.connections.icons_button');
const ConnectionsIconButton = ({ active, connections }: ConnectionsIconButtonProps) => {
  const { showContentIcon, showUserIcon, showGroupIcon } = getShowIcons(connections);

  return (
    <StyledButton active={active} aria-label={t('aria_label')} name='connections-icon-button'>
      {showContentIcon && <img src={ContentSvg} />}
      {showUserIcon && <img src={PersonSvg} />}
      {showGroupIcon && <img src={GroupsSvg} />}
    </StyledButton>
  );
};

export default ConnectionsIconButton;
