import React, { DragEvent, ReactNode, useRef } from 'react';
import styled, { useTheme } from 'styled-components';

import { ShapeType } from '../../../../../../types/Flowchart';
import Shape from '../shape';

const PreviewShape = styled(Shape)`
  display: block;
  stroke: ${({ theme: { vars } }) => vars.textDefault};
`;

const ShapeItemDragImage = styled.div`
  transform: translate(0);
  position: absolute;
  top: -100000px;
  left: -1000000px;
`;

export const ShapeItemWrapper = styled.div`
  width: 3.25rem;
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};
  cursor: grab;
  padding: 0.313rem;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${({ theme: { constants } }) => constants.spacerSm2};
  &:hover {
    background-color: ${({ theme: { vars } }) => vars.foundationHover};
  }
`;

type ShapeItemProps = {
  type: ShapeType;
  className?: string;
  dragImageType?: ShapeType;
  children?: ReactNode;
};

const ShapeMenuItem = ({ type, dragImageType, children, className }: ShapeItemProps) => {
  const theme = useTheme();
  const dragImageRef = useRef<HTMLDivElement>(null);

  const onDragStart = (event: DragEvent<HTMLDivElement>) => {
    event.dataTransfer?.setData('application/reactflow', type);

    if (dragImageRef.current) {
      event.dataTransfer.setDragImage(dragImageRef.current, 0, 0);
    }
  };

  return (
    <ShapeItemWrapper
      className={className}
      data-testid={`shape-item-${type}`}
      draggable
      onDragStart={onDragStart}
    >
      {children || (
        <PreviewShape
          fill={theme.vars.foundationSurface1}
          height={25}
          strokeWidth={2}
          type={type}
          width={25}
        />
      )}
      <ShapeItemDragImage className='sidebar-item-drag-image' ref={dragImageRef}>
        <Shape
          fill={theme.vars.foundationSurface1}
          fillOpacity={0.65}
          height={100}
          stroke={theme.vars.borderDefault}
          strokeMiterlimit={0}
          strokeOpacity={0.5}
          strokeWidth={1}
          type={dragImageType || type}
          width={100}
        />
      </ShapeItemDragImage>
    </ShapeItemWrapper>
  );
};

export default ShapeMenuItem;
