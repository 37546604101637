import React, { ReactNode, createContext, useContext } from 'react';

import { ConsumptionFlowchartResponse } from '../redux/services/resourceApis/flowcharts/types';

type BaseFlowchartConsumptionContext = {
  data: ConsumptionFlowchartResponse | undefined;
  isLoading: boolean;
  isError: boolean;
};

type FlowchartConsumptionContext = BaseFlowchartConsumptionContext;

type Props = {
  children: ReactNode;
} & BaseFlowchartConsumptionContext;

const FlowchartConsumptionDataContext = createContext({} as FlowchartConsumptionContext);
export const useFlowchartConsumptionData = () => useContext(FlowchartConsumptionDataContext);

const FlowchartConsumptionDataProvider = ({ children, data, isError, isLoading }: Props) => {
  return (
    <FlowchartConsumptionDataContext.Provider
      value={{
        data,
        isError,
        isLoading,
      }}
    >
      {children}
    </FlowchartConsumptionDataContext.Provider>
  );
};

export { FlowchartConsumptionDataProvider };
