import { EdgeProps, Position } from '@xyflow/react';

// This offset is the size of the gap between the edge and the shape node, which
// is based on the size and position of the node handle
const OFFSET_PX = 5;

/**
 * This helper takes in the current coordinates and handle positions for an edge
 * source and target, and returns modified coordinates that reduce the gap between
 * the edge and node.
 *
 * @returns new x and y coordinates for the edge source and target
 */
export const getEdgeCoords = ({
  sourceX,
  sourceY,
  sourcePosition,
  targetX,
  targetY,
  targetPosition,
}: Pick<
  EdgeProps,
  'sourceX' | 'sourceY' | 'sourcePosition' | 'targetX' | 'targetY' | 'targetPosition'
>) => {
  let newSourceX = sourceX;
  let newSourceY = sourceY;
  let newTargetX = targetX;
  let newTargetY = targetY;

  switch (sourcePosition) {
    case Position.Top:
      newSourceY += OFFSET_PX;
      break;
    case Position.Bottom:
      newSourceY -= OFFSET_PX;
      break;
    case Position.Left:
      newSourceX += OFFSET_PX;
      break;
    case Position.Right:
      newSourceX -= OFFSET_PX;
      break;
  }

  switch (targetPosition) {
    case Position.Top:
      newTargetY += OFFSET_PX;
      break;
    case Position.Bottom:
      newTargetY -= OFFSET_PX;
      break;
    case Position.Left:
      newTargetX += OFFSET_PX;
      break;
    case Position.Right:
      newTargetX -= OFFSET_PX;
      break;
  }

  return {
    sourceX: newSourceX,
    sourceY: newSourceY,
    targetX: newTargetX,
    targetY: newTargetY,
  };
};
