import React, { ReactNode } from 'react';
import styled from 'styled-components';

const ShapeMenuWrapper = styled.div`
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusLg};
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
`;

const ShapeMenuItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({ theme: { constants } }) => `${constants.spacerSm3} 0`};
  gap: ${({ theme: { constants } }) => constants.spacerSm2};
  width: 3.75rem;
`;

type ShapeMenuProps = {
  children: ReactNode;
};

const ShapeMenu = ({ children }: ShapeMenuProps) => {
  return (
    <ShapeMenuWrapper>
      <ShapeMenuItems>{children}</ShapeMenuItems>
    </ShapeMenuWrapper>
  );
};

export default ShapeMenu;
