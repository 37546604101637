import React from 'react';
import { useTheme } from 'styled-components';

const ShapeSkeletonLoader = () => {
  const {
    vars: { foundationBase1, foundationBase2 },
  } = useTheme();

  return (
    <svg
      fill='none'
      height='183'
      viewBox='0 0 544 183'
      width='544'
      xmlns='http://www.w3.org/2000/svg'
    >
      <defs>
        <linearGradient id='shimmerGradient' x1='0' x2='0' y1='0' y2='0'>
          <stop offset='10%' stopColor={foundationBase2} />
          <stop offset='50%' stopColor={foundationBase1} />
          <stop offset='90%' stopColor={foundationBase2} />
          <animate attributeName='x1' dur='1.5s' from='-100%' repeatCount='indefinite' to='100%' />
          <animate attributeName='x2' dur='1.5s' from='0%' repeatCount='indefinite' to='200%' />
        </linearGradient>
      </defs>
      <rect
        fill='url(#shimmerGradient)'
        height='81.8739'
        rx='4.17192'
        width='120.464'
        x='0'
        y='50.0201'
      />
      <rect
        fill='url(#shimmerGradient)'
        height='92.9005'
        rx='9.61326'
        transform='rotate(-45 177.828 90.1576)'
        width='92.9005'
        x='177.828'
        y='90.1576'
      />
      <rect
        fill='url(#shimmerGradient)'
        height='78.745'
        rx='5.20628'
        width='150.331'
        x='393.464'
        y='0.478508'
      />
      <rect
        fill='url(#shimmerGradient)'
        height='78.745'
        rx='5.20628'
        width='150.331'
        x='392.682'
        y='104.255'
      />
      <path
        d='M331.146 89.6533L331.821 88.9158L331.535 88.6533H331.146V89.6533ZM392.987 147.234C393.538 147.21 393.966 146.743 393.942 146.191L393.546 137.2C393.521 136.648 393.054 136.22 392.503 136.245C391.951 136.269 391.523 136.736 391.548 137.288L391.9 145.28L383.907 145.632C383.356 145.656 382.928 146.123 382.952 146.675C382.977 147.227 383.444 147.654 383.995 147.63L392.987 147.234ZM307.418 90.6533H331.146V88.6533H307.418V90.6533ZM330.471 90.3908L392.267 146.972L393.618 145.497L331.821 88.9158L330.471 90.3908Z'
        fill={foundationBase2}
      />
      <path
        d='M331.407 89.6533V90.6533H331.805L332.094 90.3802L331.407 89.6533ZM393.682 31.7963C393.697 31.2443 393.262 30.784 392.71 30.7683L383.714 30.5124C383.162 30.4967 382.702 30.9315 382.686 31.4836C382.67 32.0356 383.105 32.4959 383.657 32.5116L391.654 32.7391L391.426 40.7358C391.411 41.2879 391.846 41.7482 392.398 41.7639C392.95 41.7796 393.41 41.3448 393.426 40.7927L393.682 31.7963ZM306.115 90.6533H331.407V88.6533H306.115V90.6533ZM332.094 90.3802L393.369 32.4948L391.995 31.041L330.72 88.9264L332.094 90.3802Z'
        fill={foundationBase2}
      />
      <path
        d='M181.662 90.8864C182.054 90.4968 182.055 89.8637 181.666 89.4722L175.318 83.092C174.929 82.7005 174.295 82.6989 173.904 83.0884C173.512 83.478 173.511 84.1111 173.9 84.5027L179.543 90.1739L173.872 95.8163C173.48 96.2059 173.478 96.839 173.868 97.2305C174.258 97.6221 174.891 97.6237 175.282 97.2341L181.662 90.8864ZM120.712 91.0244L180.954 91.1775L180.96 89.1775L120.717 89.0244L120.712 91.0244Z'
        fill={foundationBase2}
      />
    </svg>
  );
};

export default ShapeSkeletonLoader;
