import React, { ReactNode, createContext, useContext } from 'react';

import { FlowchartHandlers } from '../hooks/flowcharts/useFlowchartHandlers';

type FlowchartControlContext = {
  flowchartHandlers: FlowchartHandlers;
  readonly: boolean;
};

type Props = {
  children: ReactNode;
  flowchartHandlers: FlowchartHandlers;
  readonly?: boolean;
};

const FlowchartControlContext = createContext({} as FlowchartControlContext);
export const useFlowchart = () => useContext(FlowchartControlContext);

const FlowchartControlProvider = ({ children, flowchartHandlers, readonly = false }: Props) => {
  return (
    <FlowchartControlContext.Provider value={{ flowchartHandlers, readonly }}>
      {children}
    </FlowchartControlContext.Provider>
  );
};

export { FlowchartControlProvider };
