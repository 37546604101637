import React from 'react';
import styled from 'styled-components';

import initTranslations from '../../../../../../lib/initTranslations';
import { fontMd1, fontSm4 } from '../../../../../styled/TypeSystem';
import ConnectionsSearch from '../../../FlowchartEditor/ShapeToolbar/ConnectionsFlyout/ConnectionsSearch/ConnectionsSearch';

const Header = styled.div`
  color: ${({ theme: { vars } }) => vars.textDefault};
  font-weight: ${({ theme: { constants } }) => constants.fontBold};
  ${fontMd1};
  line-height: normal;
`;

const Description = styled.div`
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
  color: ${({ theme: { vars } }) => vars.textSubdued};
  ${fontSm4};
  line-height: normal;
`;

type Props = {
  readonly?: boolean;
};

const t = initTranslations('curriculums_view.flowchart.shape_toolbar.connections.flyout');

const ConnectionsFlyoutContent = ({ readonly = false }: Props) => {
  const description = readonly ? t('readonly_description') : t('description');
  return (
    <>
      <Header>{t('header')}</Header>
      <Description>{description}</Description>
      {!readonly && <ConnectionsSearch />}
    </>
  );
};

export default ConnectionsFlyoutContent;
