import { IconName } from '@fortawesome/fontawesome-svg-core';
import React from 'react';
import styled, { css } from 'styled-components';
import { Route } from 'type-route';

import { Emoji } from '../../../../types/Emoji';
import { routes } from '../../../application/publicApplication/applicationRouter';
import { fontSm5 } from '../../../styled/TypeSystem';
import { TruncatedText } from '../../Triage/TruncatedText';
import Avatar from '../avatar/Avatar';

const Wrapper = styled.a<{ disabled: boolean }>`
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  gap: ${({ theme: { constants } }) => constants.spacerSm2};
  width: fit-content;
  max-width: 100%;
  padding: ${({ theme: { constants } }) =>
    `${constants.spacerSm1} ${constants.spacerSm3} ${constants.spacerSm1} ${constants.spacerSm1}`};
  color: ${({ theme: { vars } }) => vars.textDefault};
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
  border: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface2}`};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusEndcap};
  text-decoration: none;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

  ${({ disabled }) =>
    disabled &&
    css`
      color: ${({ theme: { vars } }) => vars.textDisabled};
      background-color: ${({ theme: { vars } }) => vars.foundationBase3};
      border-color: ${({ theme: { vars } }) => vars.borderDisabled};
    `}

  ${({ disabled }) =>
    !disabled &&
    css`
      :hover {
        background-color: ${({ theme: { vars } }) => vars.foundationBase1};
        border-color: ${({ theme: { vars } }) => vars.borderDefault};
      }
    `}
`;

const BadgeText = styled.span`
  ${fontSm5};
  ${TruncatedText({})};
`;

type AvatarProps =
  | {
      avatarImage: string | undefined;
      iconName?: never;
      emoji?: never;
    }
  | {
      avatarImage?: never;
      iconName: IconName;
      emoji?: Emoji;
    };

export type Props = {
  label: string;
  route?: Route<typeof routes>;
  disabled?: boolean;
} & AvatarProps;

const MentionBadge = ({ label, avatarImage, iconName, emoji, route, disabled = false }: Props) => {
  const linkProps =
    route && !disabled ? { href: route.href, target: '_blank', rel: 'noopener noreferrer' } : {};

  return (
    <Wrapper {...linkProps} disabled={disabled}>
      <Avatar
        emoji={emoji}
        icon={iconName}
        image={avatarImage}
        name={label}
        shape='circle'
        size='2xs'
      />
      <BadgeText>{label}</BadgeText>
    </Wrapper>
  );
};

export default MentionBadge;
