import { IconName } from '@fortawesome/fontawesome-svg-core';
import React from 'react';
import styled from 'styled-components';

import useIdRegistry from '../../../../hooks/useIdRegistry';
import { RegisteredId } from '../../../../lib/idRegistry';
import { fontSm2 } from '../../../styled/TypeSystem';
import Icon from '../../display/icons/Icon';

const Button = styled.button`
  align-items: center;
  border: none;
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
  display: flex;
  height: 3.065rem;
  flex-direction: column;
  gap: 0.0625rem;
  justify-content: center;
  min-width: 3.065rem;
  padding: ${({ theme: { constants } }) => constants.spacerSm2};
  width: 3.065rem;
  cursor: pointer;

  ${fontSm2};

  &:disabled {
    background-color: ${({ theme: { vars } }) => vars.foundationBase1};
    color: ${({ theme: { vars } }) => vars.textDisabled};
    img {
      filter: grayscale(100%);
    }
  }
  &:hover,
  &:focus {
    background-color: ${({ theme: { vars } }) => vars.accentSubdued1};
  }
`;

const ButtonImage = styled.img`
  height: 1.125rem;
  width: 1.25rem;
`;

const ButtonText = styled.span`
  color: ${({ theme: { vars } }) => vars.textDefault};
`;

export type IconProps =
  | {
      iconName: IconName;
      iconColor: string;
      iconImage?: never;
    }
  | {
      iconImage: string;
      iconName?: never;
      iconColor?: never;
    };

export type FloatingButtonProps = {
  className?: string;
  text: string;
  disabled?: boolean;
  onClick: () => void;
  id: RegisteredId;
} & IconProps;

const FloatingButton = (props: FloatingButtonProps) => {
  useIdRegistry(props.id);
  const { className, iconName, iconImage, id, text, onClick, disabled = false } = props;

  return (
    <Button className={className} data-testid={id} disabled={disabled} id={id} onClick={onClick}>
      {iconName && <Icon name={iconName} size='xs' weight='regular' />}
      {iconImage && <ButtonImage src={iconImage} />}
      <ButtonText>{text}</ButtonText>
    </Button>
  );
};

export default FloatingButton;
