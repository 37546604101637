import React from 'react';

import { SearchPaginatedResult } from '../../../../../../../redux/services/resourceApis/searches/types';
import { ConnectionData } from '../../../../../../../types/Flowchart';
import { getConnectionFromSearchResult } from '../../../../lib/getConnectionFromSearchResult';
import ConnectionRow from '../../../../shared/connections/ConnectionRow/ConnectionRow';

const ConnectionSearchResultsRows = ({
  searchResults,
  onClick,
}: {
  searchResults: SearchPaginatedResult[];
  onClick: (connection: ConnectionData) => void;
}) => {
  const connectionRows = () => {
    return searchResults.map((searchResult) => {
      const connectionData = getConnectionFromSearchResult(searchResult);

      return (
        <ConnectionRow
          connection={connectionData}
          dataTestId='connection-search-result-row'
          key={`${connectionData.type}-${connectionData.id}`}
          onClick={onClick}
        />
      );
    });
  };

  return <>{connectionRows()}</>;
};

export default ConnectionSearchResultsRows;
