import React from 'react';
import styled, { css } from 'styled-components';

import initTranslations from '../../../../../../lib/initTranslations';
import { ConnectionData, NodeConnection } from '../../../../../../types/Flowchart';
import IconButton from '../../../../../design_system/buttons/IconButton';
import DetailedAvatar from '../../../../../design_system/display/DetailedAvatar/DetailedAvatar';
import Tooltip from '../../../../../design_system/display/Tooltip/Tooltip';
import { useGetConnectionRenderData } from '../../../hooks/useGetConnectionRenderData';

const t = initTranslations('curriculums_view.flowchart.connections');

const Wrapper = styled.div<{ $deleted?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme: { constants } }) => constants.spacerSm3};
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusLg};
  text-decoration: none;

  ${({ $deleted }) =>
    $deleted &&
    css`
      background-color: ${({ theme: { vars } }) => vars.foundationBase3};

      div {
        color: ${({ theme: { vars } }) => vars.textDisabled};
      }
    `}

  ${({ $deleted }) =>
    !$deleted &&
    css`
      cursor: pointer;

      /* DS Override: DetailedAvatar conditionally shows default cursor, but we always want pointer */
      div {
        cursor: pointer;
      }

      &.selected,
      :hover {
        background-color: ${({ theme: { vars } }) => vars.foundationBase1};
      }
    `}
`;

const ActionsWrapper = styled.div`
  opacity: 0;
  display: flex;
  gap: ${({ theme: { constants } }) => constants.spacerSm2};

  ${Wrapper}:hover & {
    opacity: 1;
  }
`;

const StyledIconButton = styled(IconButton)`
  width: 2.25rem;
  height: ${({ theme: { constants } }) => constants.heightMd};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
  border: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface2}`};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusLg};

  :hover {
    background-color: ${({ theme: { vars } }) => vars.foundationBase1};
  }
`;

type ConnectionProps =
  | {
      connection: ConnectionData;
      deleted?: false;
    }
  | {
      connection: NodeConnection;
      deleted: true;
    };

export type Props = {
  onClick?: (connection: ConnectionData) => void;
  onDeleteClick?: () => void;
  onMentionClick?: () => void;
  className?: string;
  dataTestId?: string;
} & ConnectionProps;

const ConnectionRow = ({
  connection,
  deleted,
  dataTestId,
  onClick,
  onDeleteClick,
  onMentionClick,
  className,
}: Props) => {
  const renderDataArgs = deleted ? { connection, deleted } : { connection, deleted };
  const { route, detailedAvatar } = useGetConnectionRenderData(renderDataArgs);
  const clickProps = deleted
    ? {}
    : onClick
    ? {
        onClick: () => {
          onClick(connection);
        },
      }
    : { href: route.href, target: '_blank', $as: 'a' };

  const onDelete = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    onDeleteClick && onDeleteClick();
  };

  return (
    <Wrapper
      $deleted={deleted}
      className={className}
      data-testid={dataTestId || 'flowchart-connection-row'}
      {...clickProps}
    >
      <DetailedAvatar {...detailedAvatar} />
      {(onDeleteClick || onMentionClick) && (
        <ActionsWrapper>
          {onMentionClick && (
            <>
              <Tooltip id='mention-button-tooltip' text={t('mention_button.tooltip')} />
              <StyledIconButton
                ariaLabel={t('mention_button.aria_label')}
                buttonSize='sm'
                dataTip
                name='at'
                onClick={onMentionClick}
                tooltipId='mention-button-tooltip'
              />
            </>
          )}
          {onDeleteClick && (
            <>
              <Tooltip id='delete-button-tooltip' text={t('delete_button.tooltip')} />
              <StyledIconButton
                ariaLabel={t('delete_button.aria_label')}
                buttonSize='sm'
                dataTip
                name='trash-can'
                onClick={onDelete}
                tooltipId='delete-button-tooltip'
              />
            </>
          )}
        </ActionsWrapper>
      )}
    </Wrapper>
  );
};

export default ConnectionRow;
