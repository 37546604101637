import React from 'react';

import { ShapeComponentProps, ShapeComponents } from './index';

const Shape = ({ type, width, height, className = '', ...svgAttributes }: ShapeComponentProps) => {
  const ShapeComponent = ShapeComponents[type];

  if (!ShapeComponent || !width || !height) {
    return null;
  }

  const strokeWidth = svgAttributes.strokeWidth ? +svgAttributes.strokeWidth : 0;

  // we subtract the strokeWidth to make sure the shape is not cut off
  // this is done because svg doesn't support stroke inset (https://stackoverflow.com/questions/7241393/can-you-control-how-an-svgs-stroke-width-is-drawn)
  const innerWidth = width - 2 * strokeWidth;
  const innerHeight = height - 2 * strokeWidth;

  return (
    <svg className={className} height={height} width={width}>
      {/* this offsets the shape by the strokeWidth so that we have enough space for the stroke */}
      <g
        transform={`translate(${svgAttributes.strokeWidth ?? 0}, ${
          svgAttributes.strokeWidth ?? 0
        })`}
      >
        <ShapeComponent height={innerHeight} width={innerWidth} {...svgAttributes} />
      </g>
    </svg>
  );
};

export default Shape;
