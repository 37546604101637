import { useEffect, useState } from 'react';

const useMinLoadingTime = (isLoading: boolean, minLoadTimeMilliseconds = 500): boolean => {
  const [effectiveLoading, setEffectiveLoading] = useState<boolean>(true);

  useEffect(() => {
    const minLoadTimePromise = new Promise<void>((resolve) => {
      setTimeout(resolve, minLoadTimeMilliseconds);
    });

    const dataLoadedPromise = new Promise<void>((resolve) => {
      if (!isLoading) resolve();
    });

    Promise.all([minLoadTimePromise, dataLoadedPromise]).then(() => {
      setEffectiveLoading(false);
    });

    return () => {
      setEffectiveLoading(isLoading);
    };
  }, [isLoading, minLoadTimeMilliseconds]);

  return effectiveLoading;
};

export default useMinLoadingTime;
