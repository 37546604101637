import { animated, useSpring } from '@react-spring/web';
import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import useMeasure from 'react-use-measure';
import styled from 'styled-components';

import useIdRegistry from '../../../../../hooks/useIdRegistry';
import { RegisteredId } from '../../../../../lib/idRegistry';
import { IconProps } from '../../../../design_system/buttons/FloatingButton/FloatingButton';
import IconButton from '../../../../design_system/buttons/IconButton';
import Icon from '../../../../design_system/display/icons/Icon';
import { fontSm2 } from '../../../../styled/TypeSystem';

const BorderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthXs} solid ${vars.borderDefault}`};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusLg};
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
  box-shadow: ${({ theme: { vars } }) => vars.shadowTopSmall};
`;

const Button = styled.button<{ expanded: boolean }>`
  border: none;
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
  padding: ${({ theme: { constants } }) => `${constants.spacerSm3} 0`};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusLg};
  cursor: pointer;
  width: 3.75rem;
  height: 3.75rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  ${({ expanded, theme: { constants, vars } }) =>
    expanded &&
    ` border-bottom: ${constants.borderWidthXs} solid ${vars.borderDefault};
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      background-color: ${vars.foundationBase1};
    `}
  ${fontSm2};
`;

const StyledIconButton = styled(IconButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ theme: { constants } }) => `${constants.spacerSm2} 0`};
  border-radius: 0 0 ${({ theme: { constants } }) => constants.borderRadiusLg}
    ${({ theme: { constants } }) => constants.borderRadiusLg};
  border-top: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthXs} solid ${vars.borderDefault}`};
  height: ${({ theme: { constants } }) => constants.heightSm};
  background-color: ${({ theme: { vars } }) => vars.foundationBase1};
`;

const ExpandedMenu = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusLg};
`;

const AnimatedExpansion = styled(animated.div)`
  overflow: hidden;
`;
const ButtonImage = styled.img`
  height: 1.75rem;
  width: 1.75rem;
`;

type ExpandableFloatingButtonProps = {
  className?: string;
  disabled?: boolean;
  onClick?: () => void;
  onClose?: () => void;
  isExpanded?: boolean;
  id: RegisteredId;
  children: ReactNode;
} & IconProps;

const ExpandableFloatingButton = (props: ExpandableFloatingButtonProps) => {
  useIdRegistry(props.id);
  const { className, iconName, iconImage, id, onClick, onClose, disabled = false } = props;

  const isControlled = typeof props.isExpanded === 'boolean';
  const [isExpanded, setIsExpanded] = useState<boolean>(true);

  const handleClick = useCallback(() => {
    if (!isControlled) {
      setIsExpanded(!isExpanded);
    }
    onClick?.();
  }, [onClick, isControlled, isExpanded]);

  const handleClose = useCallback(() => {
    if (!isControlled) {
      setIsExpanded(false);
    }
    onClose?.();
  }, [onClose, isControlled]);

  useEffect(() => {
    if (typeof props.isExpanded === 'boolean') {
      if (isExpanded !== props.isExpanded) {
        setIsExpanded(props.isExpanded);
      }
    }
  }, [props.isExpanded, isExpanded]);

  const [ref, bounds] = useMeasure();
  const expandAnimation = useSpring({
    height: isExpanded ? bounds.height : 0,
    opacity: isExpanded ? 1 : 0,
    config: { tension: 400, friction: 40 },
  });

  return (
    <BorderWrapper>
      <Button
        className={className}
        data-testid={id}
        disabled={disabled}
        expanded={isExpanded}
        id={id}
        onClick={handleClick}
      >
        {iconName && <Icon name={iconName} size='xs' weight='regular' />}
        {iconImage && <ButtonImage src={iconImage} />}
      </Button>
      <AnimatedExpansion style={expandAnimation}>
        <div ref={ref}>
          {isExpanded && (
            <ExpandedMenu>
              {props.children}
              <StyledIconButton
                ariaLabel='test'
                buttonSize='sm'
                name='xmark'
                onClick={handleClose}
              />
            </ExpandedMenu>
          )}
        </div>
      </AnimatedExpansion>
    </BorderWrapper>
  );
};

export default ExpandableFloatingButton;
