import React from 'react';
import styled, { useTheme } from 'styled-components';

import { SkeletonLoader } from '../../shared/SkeletonLoader/styles';
import { PanelStyles } from './Panel/FloatingPanel';
import ShapeSkeletonLoader from './ShapeSkeletonLoader/ShapeSkeletonLoader';

const CanvasLoaderWrapper = styled.div`
  display: flex;
  height: 100%;
`;

const ShapeLoaderWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
`;

const StyledPanel = styled.div`
  ${PanelStyles};
`;

type Props = {
  readonly: boolean;
};

const CanvasLoader = ({ readonly }: Props) => {
  const {
    constants: { borderRadiusLg },
  } = useTheme();

  return (
    <CanvasLoaderWrapper>
      {!readonly && (
        <StyledPanel>
          <SkeletonLoader borderRadius={borderRadiusLg} height='22.125rem' width='3.5rem' />
        </StyledPanel>
      )}
      <ShapeLoaderWrapper>
        <ShapeSkeletonLoader />
      </ShapeLoaderWrapper>
    </CanvasLoaderWrapper>
  );
};

export default CanvasLoader;
