import React from 'react';
import styled, { useTheme } from 'styled-components';

import initTranslations from '../../../../../lib/initTranslations';
import { fontSm4 } from '../../../../styled/TypeSystem';
import { MIN_CHARACTERS_TO_SEARCH } from './ConnectionsList';

const t = initTranslations('curriculums_view.flowchart.connections.mentions_flyout');

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${({ theme: { constants } }) => constants.spacerMd2};
  height: 6.25rem;
`;

const Text = styled.div`
  color: ${({ theme: { vars } }) => vars.accentPrimaryDefault};
  font-style: italic;
  ${fontSm4};
`;

const LoaderSvg = () => {
  const {
    vars: { accentPrimaryDefault },
  } = useTheme();

  return (
    <svg fill='none' height='20' viewBox='0 0 50 20' width='50' xmlns='http://www.w3.org/2000/svg'>
      <g>
        <circle cx='5' cy='15' fill={accentPrimaryDefault} r='5'>
          <animate attributeName='cy' dur='1s' repeatCount='indefinite' values='15;5;15;15;15;15' />
        </circle>
        <circle cx='25' cy='15' fill={accentPrimaryDefault} r='5'>
          <animate attributeName='cy' dur='1s' repeatCount='indefinite' values='15;11;5;15;15;15' />
        </circle>
        <circle cx='45' cy='15' fill={accentPrimaryDefault} r='5'>
          <animate attributeName='cy' dur='1s' repeatCount='indefinite' values='15;15;11;5;15;15' />
        </circle>
      </g>
    </svg>
  );
};

type Props = {
  query: string;
};

const MentionsPlaceholder = ({ query }: Props) => {
  return (
    <Wrapper>
      <LoaderSvg />
      <Text>{t('placeholder', { count: MIN_CHARACTERS_TO_SEARCH - query.length })}</Text>
    </Wrapper>
  );
};

export default MentionsPlaceholder;
