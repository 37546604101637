import { useNodeId } from '@xyflow/react';
import React from 'react';
import styled from 'styled-components';

import { useFlowchart } from '../../../../../../contexts/FlowchartControlProvider';
import { RegisteredMenuId } from '../../../../../../lib/idRegistry';
import { NodeConnection } from '../../../../../../types/Flowchart';
import Flyout from '../../../../../design_system/overlays/flyout';
import useActiveMenuHandler from '../../../../publicApplication/utils/useActiveMenuHandler';
import ConnectionsButton from '../../../FlowchartEditor/ShapeToolbar/ConnectionsFlyout/ConnectionsButton';
import ConnectionsFlyoutContent from './ConnectionsFlyoutContent';
import ConnectionsFlyoutList from './ConnectionsFlyoutList';
import ConnectionsIconButton from './ConnectionsIconButton';

const StyledFlyout = styled(Flyout)<{ $iconsButton: boolean }>`
  width: 30rem;
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusLg};
  box-shadow: ${({ theme: { vars } }) => vars.shadowCenterMedium};
  /* Our Flyout component seems to run into issues with react-flow unless it's set to bottom or bottom-start.
   This adds some margin to replicate what we really want (bottom-end) */
  margin-left: ${({ $iconsButton }) => ($iconsButton ? '' : '11rem')};
`;

type Props = {
  connections: NodeConnection[];
  iconsButton?: boolean;
};

const ConnectionsFlyout = ({ connections, iconsButton = false }: Props) => {
  const { readonly } = useFlowchart();
  const nodeId = useNodeId();

  const FlowchartConnectionsFlyoutId: RegisteredMenuId = `flowchart-connections-flyout-${nodeId}`;
  const FlowchartConnectionsIconsFlyoutId: RegisteredMenuId = `flowchart-connections-icons-flyout-${nodeId}`;
  const FlyoutId = iconsButton ? FlowchartConnectionsIconsFlyoutId : FlowchartConnectionsFlyoutId;
  const { isMenuOpen } = useActiveMenuHandler({ menuId: FlyoutId });

  const TriggerComponent = iconsButton ? ConnectionsIconButton : ConnectionsButton;

  return (
    <StyledFlyout
      $iconsButton={iconsButton}
      className='nowheel nopan'
      data-testid='connections-flyout'
      hideHeader
      id={FlyoutId}
      placement='bottom-start'
      strategy='absolute'
      triggerButton={<TriggerComponent active={isMenuOpen} connections={connections} />}
    >
      <ConnectionsFlyoutContent readonly={readonly} />
      <ConnectionsFlyoutList connections={connections} readonly={readonly} />
    </StyledFlyout>
  );
};

export default ConnectionsFlyout;
