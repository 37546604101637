import { NodeToolbar, useNodeId } from '@xyflow/react';
import React, { useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';

import TextColorToolbarButton from '../../../../../../components/application/editor/toolbar/buttons/components/format/TextColorToolbarButton';
import { useEditorContext } from '../../../../../../contexts/EditorContext';
import { useFlowchart } from '../../../../../../contexts/FlowchartControlProvider';
import { formatOptions } from '../../../../../design_system/core/CoreSelectField/CoreSelectField';
import SingleSelectField from '../../../../../design_system/Triage/fields/SingleSelectField';
import { FONT_SIZES } from '../../../../editor/shared/constants/editor';
import BoldToolbarButton from '../../../../editor/toolbar/buttons/components/format/BoldToolbarButton';
import ItalicToolbarButton from '../../../../editor/toolbar/buttons/components/format/ItalicToolbarButton';
import UnderlineToolbarButton from '../../../../editor/toolbar/buttons/components/format/UnderlineToolbarButton';
import LinkToolbarButton from '../../../../editor/toolbar/buttons/components/links/LinkToolbarButton';

const FontSizeDropdown = styled(SingleSelectField)<{ isMenuOpen: boolean }>`
  min-height: ${({ theme: { constants } }) => constants.heightSm};
  border: none;
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};
  color: ${({ theme: { vars } }) => vars.textDefault};
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};

  ${({ isMenuOpen }) =>
    isMenuOpen &&
    css`
      .react-select__single-value,
      .react-select__indicator svg {
        color: ${({ theme: { vars } }) => vars.accentPrimaryDefault};
      }

      .react-select__control {
        background-color: ${({ theme: { vars } }) => vars.accentSubdued1};
      }
    `}

  .react-select__control {
    border: none !important;
    border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};

    :hover {
      border: none !important;
      background-color: ${({ isMenuOpen, theme: { vars } }) => !isMenuOpen && vars.foundationHover};
    }
  }
`;

const StyledNodeToolbar = styled(NodeToolbar)`
  display: flex;
  padding: ${({ theme: { constants } }) => constants.spacerSm2};
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
  border: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface2}`};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusLg};
  box-shadow: ${({ theme: { vars } }) => vars.shadowTopMedium};
  align-items: center;
`;

const TextEditToolbar = () => {
  const { editor } = useEditorContext();
  const nodeId = useNodeId();
  const { flowchartHandlers } = useFlowchart();
  const { fontSize, setFontSize, getFontSize } = flowchartHandlers;
  const [menuOpen, setMenuOpen] = useState(false);

  const [currentFontSize, setCurrentFontSize] = useState(fontSize.toString());

  useEffect(() => {
    if (nodeId) {
      const size = getFontSize(nodeId).toString();
      setCurrentFontSize(size);
    }
  }, [nodeId, getFontSize]);

  const fontSizeOptions = () => {
    return formatOptions(
      FONT_SIZES.map((fontSize) => ({
        label: fontSize,
        value: fontSize,
      }))
    );
  };

  const handleFontSizeChange = (value: string) => {
    if (!nodeId) {
      return;
    }
    setFontSize(Number(value), nodeId);
  };

  const defaultValue = useMemo(
    () => (nodeId ? getFontSize(nodeId).toString() : FONT_SIZES[0]),
    [getFontSize, nodeId]
  );

  if (!editor) {
    return null;
  }

  return (
    <StyledNodeToolbar className='nopan' offset={10}>
      <FontSizeDropdown
        className='nowheel'
        defaultValue={defaultValue}
        expandMenuToFitOptions
        hideSelectedOptions={false}
        isMenuOpen={menuOpen}
        isSearchable
        onMenuClose={() => {
          setMenuOpen(false);
        }}
        onMenuOpen={() => {
          setMenuOpen(true);
        }}
        onNonDefaultSelected={(value) => {
          if (value) {
            handleFontSizeChange(value.toString());
          }
        }}
        options={fontSizeOptions()}
        size='sm'
        value={currentFontSize}
      />
      <BoldToolbarButton />
      <ItalicToolbarButton />
      <UnderlineToolbarButton />
      <LinkToolbarButton />
      <TextColorToolbarButton />
    </StyledNodeToolbar>
  );
};

export default TextEditToolbar;
