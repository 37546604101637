import {
  Handle,
  NodeProps,
  NodeResizer,
  Position,
  ReactFlowState,
  useKeyPress,
  useStore,
} from '@xyflow/react';
import React, { useCallback } from 'react';
import styled, { useTheme } from 'styled-components';

import { EditorProvider } from '../../../../../../contexts/EditorContext';
import { EditorToolbarProvider } from '../../../../../../contexts/EditorToolbarContext';
import { useFlowchart } from '../../../../../../contexts/FlowchartControlProvider';
import { ShapeNode } from '../../../../../../types/Flowchart';
import ShapeToolbar from '../../../FlowchartEditor/ShapeToolbar/ShapeToolbar';
import { useShapeEditor } from '../../../hooks/useShapeEditor';
import ConnectionsIconsToolbar from '../../connections/ConnectionsFlyout/ConnectionsIconsToolbar';
import Shape from '../shape';
import NodeLabel from './label';

const connectionNodeIdSelector = (state: ReactFlowState) => state.connection.fromHandle?.nodeId;

// this will return the current dimensions of the node (measured internally by react flow)
const useNodeDimensions = (id: string) => {
  const node = useStore((state) => state.nodeLookup.get(id));

  return {
    width: node?.width || 0,
    height: node?.height || 0,
  };
};

const StyledShape = styled(Shape)`
  fill: ${({ theme: { vars } }) => vars.foundationSurface1};
  stroke: ${({ theme: { vars } }) => vars.borderDefault};
  filter: drop-shadow(${({ theme: { vars } }) => vars.shadowCenterSmall});
`;

const ShapeNode = ({ id, selected = false, data }: NodeProps<ShapeNode>) => {
  const {
    flowchartHandlers: { getNode, setNodes },
    readonly,
  } = useFlowchart();
  const { type, fontSize } = data;
  const { width, height } = useNodeDimensions(id);
  const shiftKeyPressed = useKeyPress('Shift');

  const {
    vars: {
      accentPrimaryDefault,
      borderDefault,
      foundationSurface1,
      shadowCenterMedium,
      accentSubdued4,
    },
    constants: { borderRadiusXs, borderRadiusSm, borderWidthSm },
  } = useTheme();

  const connectionNodeId = useStore(connectionNodeIdSelector);

  const isConnecting = !!connectionNodeId;
  const connectionEndHandle = useStore((state) => state.connection.toHandle);
  const isTarget = connectionEndHandle?.nodeId === id;
  const targetHandleId = connectionEndHandle?.id;

  const handleUpdate = useCallback(
    (content) => {
      setNodes((nodes) =>
        nodes.map((node) => {
          if (node.id === id) {
            return { ...node, data: { ...node.data, label: content } };
          }
          return node;
        })
      );
    },
    [id, setNodes]
  );

  const { editor } = useShapeEditor({ nodeId: id, handleUpdate, selected });

  if (!getNode(id) || !editor) {
    return null;
  }

  const handleStyle = (handleId: string) => ({
    backgroundColor:
      isTarget && targetHandleId === handleId ? accentPrimaryDefault : foundationSurface1,
    border: isTarget
      ? `${borderRadiusXs} solid ${accentPrimaryDefault}`
      : `${borderRadiusXs} solid ${accentSubdued4}`,
    opacity: isTarget ? 1 : '',
    width: isTarget ? 8 : 12,
    height: isTarget ? 8 : 12,
  });

  const handleCircleStyle = {
    width: `calc(0.5rem / var(--flowchart-zoom, 1))`,
    height: `calc(0.5rem / var(--flowchart-zoom, 1))`,
    opacity: isTarget ? 0 : 1,
  };

  const resizeLineStyle = {
    borderColor: accentPrimaryDefault,
  };

  const resizeHandleStyle = {
    width: isTarget ? 8 : `calc(0.75rem / var(--flowchart-zoom, 1))`,
    height: isTarget ? 8 : `calc(0.75rem / var(--flowchart-zoom, 1))`,
    backgroundColor: foundationSurface1,
    border: `calc(${borderWidthSm} / var(--flowchart-zoom, 1)) solid ${borderDefault}`,
    borderRadius: `calc(${borderRadiusSm} / var(--flowchart-zoom, 1))`,
    boxShadow: shadowCenterMedium,
    zIndex: 1,
  };

  const isSelected = selected && !readonly;
  const showShapeToolbar = !isConnecting && !readonly;

  return (
    <>
      <EditorProvider editor={editor}>
        <EditorToolbarProvider buttons={[]} context='docked'>
          {showShapeToolbar && <ShapeToolbar />}
          <ConnectionsIconsToolbar />
          {!readonly && (
            <NodeResizer
              handleStyle={resizeHandleStyle}
              isVisible={selected && !isConnecting}
              keepAspectRatio={shiftKeyPressed}
              lineStyle={resizeLineStyle}
            />
          )}

          <div className={`shape-node ${selected && !isConnecting ? 'selected' : ''}`}>
            <StyledShape
              height={height}
              stroke={isTarget ? accentPrimaryDefault : borderDefault}
              strokeMiterlimit={0}
              strokeOpacity={isTarget ? 1 : 0.5}
              strokeWidth={1}
              type={type}
              width={width}
            />
            <Handle
              className='hover-circle'
              id='top'
              isConnectable={!readonly}
              position={Position.Top}
              style={handleStyle('top')}
              type='source'
            >
              <div className='handle-circle' style={handleCircleStyle} />
            </Handle>
            <Handle
              className='hover-circle'
              id='right'
              isConnectable={!readonly}
              position={Position.Right}
              style={handleStyle('right')}
              type='source'
            >
              <div className='handle-circle' style={handleCircleStyle} />
            </Handle>
            <Handle
              className='hover-circle'
              id='bottom'
              isConnectable={!readonly}
              position={Position.Bottom}
              style={handleStyle('bottom')}
              type='source'
            >
              <div className='handle-circle' style={handleCircleStyle} />
            </Handle>
            <Handle
              className='hover-circle'
              id='left'
              isConnectable={!readonly}
              position={Position.Left}
              style={handleStyle('left')}
              type='source'
            >
              <div className='handle-circle' style={handleCircleStyle} />
            </Handle>

            <NodeLabel fontSize={fontSize} nodeId={id} selected={isSelected} />
          </div>
        </EditorToolbarProvider>
      </EditorProvider>
    </>
  );
};

export default ShapeNode;
