import { Node } from '@xyflow/react';

import { IndexedByShape, ShapeType } from '../../../../../types/Flowchart';

type ShapeDimensionFunction = (
  width: number,
  height: number
) => {
  top: number;
  left: number;
  width: number;
  height: number;
};

const ShapeDimensionFunctions: IndexedByShape<ShapeDimensionFunction> = {
  circle: (width: number, height: number) => {
    const newWidth = Math.sin(Math.PI / 4) * width * 1;
    const newHeight = Math.cos(Math.PI / 4) * height * 1;
    return {
      top: 0,
      left: 0,
      height: newHeight,
      width: newWidth,
    };
  },
  rectangle: (width: number, height: number) => {
    return {
      top: 0,
      left: 0,
      width: width * 0.75,
      height: height * 0.75,
    };
  },
  diamond: (width: number, height: number) => {
    return {
      top: 0,
      left: 0,
      width: width / 2,
      height: height / 2,
    };
  },
  cylinder: (width: number, height: number) => {
    const asRect = ShapeDimensionFunctions['rectangle'](width, height);
    asRect.top = asRect.height - asRect.height * 0.8;
    asRect.height *= 0.8;
    return asRect;
  },
  triangle: (width: number, height: number) => {
    const asRect = ShapeDimensionFunctions['rectangle'](width * 0.5, height * 0.78);
    asRect.top = height - asRect.height - height * 0.05;
    return asRect;
  },
  text: (width: number, height: number) => {
    return {
      top: 0,
      left: 0,
      width: width - 5,
      height: height - 5,
    };
  },
};

export const generatePath = (points: number[][]) => {
  const path = points.map(([x, y]) => `${x},${y}`).join(' L');

  return `M${path} Z`;
};

export const getShapeInteriorEditorDimensions = (node?: Node) => {
  if (node && typeof node.width === 'number' && typeof node.height === 'number') {
    const type = node.data.type as ShapeType;
    return ShapeDimensionFunctions[type](node.width, node.height);
  }
  return {
    top: 0,
    left: 0,
    width: 0,
    height: 0,
  };
};
