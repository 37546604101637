import { EditorContent } from '@tiptap/react';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

import { useEditorContext } from '../../../../../../contexts/EditorContext';
import { useFlowchart } from '../../../../../../contexts/FlowchartControlProvider';
import { getShapeInteriorEditorDimensions } from '../utils';

const EditorWrapper = styled.div`
  position: absolute;
  overflow: hidden;
  text-align: center;
  background: none;
`;

const LabelWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: all;
`;

const Overlay = styled.div`
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledEditorContent = styled(EditorContent)`
  overflow-y: auto;
  font-size: inherit;

  .ProseMirror {
    &:focus {
      outline: none;
    }
  }

  /* @tiptap/extension-placeholder: show placeholder when empty */
  p.is-editor-empty:first-child {
    height: 100%;
    &::before {
      position: fixed;
      color: ${({ theme: { vars } }) => vars.textDisabled};
      content: attr(data-placeholder);
      pointer-events: none;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
    }
  }
`;

type Props = {
  nodeId: string;
  selected: boolean;
  fontSize: number;
};

const NodeLabel = ({ nodeId, selected, fontSize }: Props) => {
  const editorWrapperRef = useRef<HTMLDivElement>(null);
  const {
    flowchartHandlers: { getNode, focusNodeLabelEditor },
  } = useFlowchart();
  const node = getNode(nodeId);
  const { editor } = useEditorContext();

  useEffect(() => {
    const handleBlur = () => {
      if (editorWrapperRef.current) {
        editorWrapperRef.current.scrollTop = 0;
      }
    };

    editor.on('blur', handleBlur);

    return () => {
      editor.off('blur', handleBlur);
    };
  }, [editor]);

  const editorBounds = getShapeInteriorEditorDimensions(node);

  const handleDoubleClick = () => {
    node && focusNodeLabelEditor(node);
  };

  const handleClick = () => {
    if (selected) {
      node && focusNodeLabelEditor(node);
    }
  };

  return (
    <LabelWrapper onClick={handleClick} onDoubleClick={handleDoubleClick}>
      <EditorWrapper
        ref={editorWrapperRef}
        style={{
          fontSize: fontSize + 'px',
          width: editorBounds?.width,
          maxHeight: editorBounds?.height,
          marginLeft: editorBounds?.left,
          marginTop: editorBounds?.top,
        }}
      >
        {!selected && <Overlay />}
        <StyledEditorContent
          className={selected ? 'nodrag' : ''}
          data-testid='node-editor-content'
          editor={editor}
        />
      </EditorWrapper>
    </LabelWrapper>
  );
};

export default NodeLabel;
