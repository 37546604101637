import includes from '../../../../lib/includes';
import { SearchPaginatedResult } from '../../../../redux/services/resourceApis/searches/types';
import { ConnectionData, nodeConnectionModels } from '../../../../types/Flowchart';

export const getConnectionFromSearchResult = (
  searchResult: SearchPaginatedResult
): ConnectionData => {
  if (!includes(nodeConnectionModels, searchResult.type)) {
    throw new Error('Search result type not supported in flowchart connections');
  }

  const { id, title = '', emoji = '', type, name = '', avatar } = searchResult;

  switch (type) {
    case 'Curriculum':
    case 'Course':
    case 'Survey::Survey':
    case 'Flowchart':
    case 'Step':
      return { id, type, title, emoji };
    case 'Group':
      return { id, type, name };
    case 'User': {
      const typedAvatar = avatar || null;

      return { id, type, name, avatar: typedAvatar };
    }
  }
};
