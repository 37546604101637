import { Panel } from '@xyflow/react';
import React from 'react';
import styled, { css, useTheme } from 'styled-components';

import { useFlowchart } from '../../../../../contexts/FlowchartControlProvider';
import ContentSvg from '../../../../../images/flowchart/content.svg';
import GroupsSvg from '../../../../../images/flowchart/group.svg';
import PersonSvg from '../../../../../images/flowchart/person.svg';
import ShapesSvg from '../../../../../images/flowchart/shapes.svg';
import initTranslations from '../../../../../lib/initTranslations';
import { shapeTypes } from '../../../../../types/Flowchart';
import FloatingButton from '../../../../design_system/buttons/FloatingButton/FloatingButton';
import Icon from '../../../../design_system/display/icons/Icon';
import { fontSm2 } from '../../../../styled/TypeSystem';
import ExpandableFloatingButton from '../../FlowchartEditor/ExpandableFloatingButton/ExpandableFloatingButton';
import { ShapeMenu, ShapeMenuItem } from '../shapes/sidebar';

const allShapesExceptText = shapeTypes.filter((type) => type !== 'text');

export const PanelStyles = css`
  display: flex;
  margin: ${({ theme: { constants } }) => `2.65rem 0 0 ${constants.spacerMd2}`};
  padding: ${({ theme: { constants } }) => constants.spacerSm1};
`;

const StyledPanel = styled(Panel)`
  ${PanelStyles};
`;

const ButtonPanel = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme: { constants } }) => constants.spacerMd2};
`;

const FloatingButtonGroup = styled.div`
  border: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthXs} solid ${vars.borderDefault}`};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusLg};
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
  box-shadow: ${({ theme: { vars } }) => vars.shadowTopSmall};

  min-height: 3.75rem;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  & > * {
    width: 100%;
    aspect-ratio: 1/1;
    border: none;
    background: none;
    height: auto;
    border-radius: 0;

    border-bottom: ${({ theme: { constants, vars } }) =>
      `${constants.borderWidthXs} solid ${vars.borderDefault}`};

    &:last-child {
      border-bottom: none;
    }
  }
`;

const TextMenuItemLabel = styled.span`
  color: ${({ theme: { vars } }) => vars.textDefault};
  ${fontSm2}
`;

const TextShapeMenuItem = styled(ShapeMenuItem)`
  &:hover {
    background-color: transparent;
  }
`;

const t = initTranslations('curriculums_view.flowchart');

const FloatingPanel = () => {
  const theme = useTheme();
  const { flowchartHandlers } = useFlowchart();
  const { canRedo, canUndo, redo, undo } = flowchartHandlers;

  // This value is manually set to false, because it won't be developed until this epic: https://trainual.atlassian.net/browse/GS2-5408
  const displayAssociationDifferentiators = false;

  return (
    <StyledPanel position='top-left'>
      <ButtonPanel>
        <ExpandableFloatingButton iconImage={ShapesSvg} id='flowchart-shapes-button'>
          <ShapeMenu>
            {allShapesExceptText.map((type) => {
              return <ShapeMenuItem key={type} type={type} />;
            })}
          </ShapeMenu>
        </ExpandableFloatingButton>
        <FloatingButtonGroup>
          <ShapeMenu>
            <TextShapeMenuItem dragImageType='rectangle' type='text'>
              <Icon name='text' size='xs' weight='regular' />
              <TextMenuItemLabel>{t('shapes.types.text')}</TextMenuItemLabel>
            </TextShapeMenuItem>
          </ShapeMenu>
        </FloatingButtonGroup>
        {displayAssociationDifferentiators && (
          <FloatingButtonGroup>
            <FloatingButton
              iconImage={ContentSvg}
              id='flowchart-content-button'
              onClick={() => ({})}
              text={t('floating_action_bar.floating_buttons.content')}
            />
            <FloatingButton
              iconImage={PersonSvg}
              id='flowchart-person-button'
              onClick={() => ({})}
              text={t('floating_action_bar.floating_buttons.person')}
            />
            <FloatingButton
              iconImage={GroupsSvg}
              id='flowchart-group-button'
              onClick={() => ({})}
              text={t('floating_action_bar.floating_buttons.group')}
            />
          </FloatingButtonGroup>
        )}
        <FloatingButtonGroup>
          <FloatingButton
            disabled={canRedo}
            iconColor={theme.vars.textDefault}
            iconName='arrow-rotate-right'
            id='flowchart-redo-button'
            onClick={redo}
            text={t('floating_action_bar.floating_buttons.redo')}
          />
          <FloatingButton
            disabled={canUndo}
            iconColor={theme.vars.textDefault}
            iconName='arrow-rotate-left'
            id='flowchart-undo-button'
            onClick={undo}
            text={t('floating_action_bar.floating_buttons.undo')}
          />
        </FloatingButtonGroup>
      </ButtonPanel>
    </StyledPanel>
  );
};

export default FloatingPanel;
