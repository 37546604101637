import { Edge, Node, XYPosition } from '@xyflow/react';
import { v4 as uuidv4 } from 'uuid';

import { SerializedEdge, SerializedNode } from '../../../../types/Flowchart';
import { serializeEdges, serializeNodes } from './serialize';

export interface FlowchartPastableNodes {
  flowchartData: {
    flowchartId: number;
    nodes: SerializedNode[];
    edges: SerializedEdge[];
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isFlowchartPastableNodes = (obj: any): obj is FlowchartPastableNodes => {
  if (typeof obj?.flowchartData?.flowchartId !== 'number') {
    return false;
  }
  const nodes = obj?.flowchartData?.nodes;
  if (!Array.isArray(nodes)) {
    return false;
  }
  const node = nodes[0];
  return node?.id && node?.position?.x;
};

/**
 * Parse text as JSON
 * If the text is valid flowchartData JSON, return the parsed object
 * If text is not JSON or not flowchartData, return null;
 */
export const parseFlowchartData = (text: string): FlowchartPastableNodes | null => {
  if (!text.includes('flowchartData')) {
    return null;
  }

  let parsed;
  try {
    parsed = JSON.parse(text);
  } catch (e) {
    return null;
  }

  if (isFlowchartPastableNodes(parsed)) {
    return parsed;
  } else {
    return null;
  }
};

/**
 * Sanitize the given nodes and edges before a copy, cut, or paste operation.
 * @returns `FlowchartPastableNodes`
 * The nodes and edges are serialized to ensure they contain only the expected
 * data. The ids of all nodes and edges are re-generated and edge connections
 * are updated to reflect the new node ids.
 * Any edges that are not connected to one of the given nodes are removed from the result.
 */
const prepareFlowchartPastableNodes = (
  flowchartId: number,
  nodesToInclude: Node[],
  edgesToInclude: Edge[]
): FlowchartPastableNodes => {
  const nodes = serializeNodes(nodesToInclude);
  const edges = serializeEdges(edgesToInclude);
  const idMap: Record<string, string> = {};
  for (const n of nodes) {
    const newId = uuidv4();
    idMap[n.id as string] = newId;
    n.id = newId;
  }
  for (const e of edges) {
    const newId = uuidv4();
    idMap[e.id as string] = newId;
    e.id = newId;
    if (e.source) {
      e.source = idMap[e.source];
    }
    if (e.target) {
      e.target = idMap[e.target];
    }
  }
  const data = {
    flowchartData: {
      flowchartId,
      nodes,
      edges: edges.filter(({ source, target }) => {
        return source && target;
      }),
    },
  };
  return data;
};

export const copyFlowchartDataToClipboard = (
  flowchartId: number,
  nodesToInclude: Node[],
  edgesToInclude: Edge[]
) => {
  const data = prepareFlowchartPastableNodes(flowchartId, nodesToInclude, edgesToInclude);

  const asText = JSON.stringify(data);

  if (typeof navigator.clipboard?.writeText === 'function') {
    navigator.clipboard.writeText(asText);
  } else {
    copyViaExecCommand(asText);
  }
};

/**
 * Copy `text` to clipboard using document.execCommand
 *
 * This is a deprecated method of copying to the clipboard.
 * It is used as a fallback when navigator.clipboard is not available
 * either because the user has denied permission, or the app is not
 * running in a `window.isSecureContext` setting (such as local dev under http)
 */
const copyViaExecCommand = (text: string) => {
  const textArea = document.createElement('textarea');
  textArea.value = text;
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  // Exec command 'copy' will trigger the copy handler that got
  // us here in the first place, the handler checks for this attribute
  // to prevent recursive events. extra verbose for greppability
  textArea.setAttribute('data-is-temporary-flowchart-copy-element', '1');
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();
  document.execCommand('copy');
  document.body.removeChild(textArea);
};

/**
 * Given a flowchart XY `position` and pastable `data`:
 * return a new FlowchartPastableNodes where
 * nodes and edges are modified such that the top left
 * of the bounding box of all elements will be at `position`
 */
export const transformPastablePositions = (
  position: XYPosition,
  pasteData: FlowchartPastableNodes
) => {
  const data = prepareFlowchartPastableNodes(
    pasteData.flowchartData.flowchartId,
    pasteData.flowchartData.nodes,
    pasteData.flowchartData.edges
  );

  const newNodes: Node[] = data.flowchartData.nodes;

  // Find the top left coordinate of the new nodes' bounding box
  const minXY = newNodes.reduce(
    (origin, node) => {
      if (origin.x === null || node.position.x < origin.x) {
        origin.x = node.position.x;
      }
      if (origin.y === null || node.position.y < origin.y) {
        origin.y = node.position.y;
      }
      return origin;
    },
    { x: null, y: null } as { x: number | null; y: number | null }
  ) as XYPosition;

  for (const n of newNodes) {
    // set the xy position of all new nodes such that the inserted nodes'
    // bounding box top left is at the mouse position
    n.position.x = n.position.x - minXY.x + position.x;
    n.position.y = n.position.y - minXY.y + position.y;
  }

  return data;
};
