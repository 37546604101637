/**
 * Determines whether an array includes a certain string value among its entries, while
 * providing a type guard such that after calling the function TypeScript will
 * treat the value as belonging to the type of the array values.
 *
 * This can be useful in cases where we need to check whether a value can be used
 * as a member of a union type, where otherwise Array.prototype.includes() will not
 * provide that type guard.
 *
 * See StackOverflow thread for more background: https://stackoverflow.com/a/74230174
 *
 * @returns {boolean} true if the value is found in the array
 */
const includes = <T extends string>(array: readonly T[], value: string): value is T => {
  return (array as readonly string[]).includes(value);
};

export default includes;
