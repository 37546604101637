import { IconName } from '@fortawesome/fontawesome-svg-core';
import { Route } from 'type-route';

import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import useCurriculumElement from '../../../../hooks/useCurriculumElement';
import initTranslations from '../../../../lib/initTranslations';
import { ConnectionData, NodeConnection } from '../../../../types/Flowchart';
import { useAccountTerminology } from '../../../AccountTerminologyProvider';
import { DetailedAvatarProps } from '../../../design_system/display/DetailedAvatar/DetailedAvatar';
import { Props as MentionBadgeProps } from '../../../design_system/display/MentionBadge/MentionBadge';
import { routes } from '../../publicApplication/applicationRouter';

const t = initTranslations('curriculums_view.flowchart.connections');

type ConnectionArgs =
  | {
      connection: ConnectionData;
      deleted?: false;
    }
  | {
      connection: NodeConnection;
      deleted: true;
    };

type ConnectionRenderData = {
  route: Route<typeof routes>;
  detailedAvatar: DetailedAvatarProps;
  mentionBadge: MentionBadgeProps;
};

export const useGetConnectionRenderData = ({
  connection,
  deleted,
}: ConnectionArgs): ConnectionRenderData => {
  const { slug } = useCurrentAccount();
  const {
    curriculum: { singular: curriculumTerm },
    step: { singular: stepTerm },
  } = useAccountTerminology();
  const elementAttributes = useCurriculumElement();

  switch (connection.type) {
    case 'Curriculum': {
      const iconName = 'file-lines';

      return {
        route: routes.curriculumShow({ slug, id: connection.id }),
        detailedAvatar: {
          avatarIcon: iconName,
          avatarEmoji: deleted ? undefined : connection.emoji,
          label: deleted ? t('deleted.content') : connection.title,
          subLabel: t('row_sublabel.content', { content_term: curriculumTerm.toLowerCase() }),
        },
        mentionBadge: {
          iconName,
          emoji: deleted ? undefined : connection.emoji,
          label: deleted ? t('deleted.content') : connection.title,
        },
      };
    }
    case 'Course':
    case 'Survey::Survey':
    case 'Flowchart': {
      const { iconName, termSingular, showRoute } = elementAttributes({
        elementType: connection.type,
        elementId: connection.id,
      });
      return {
        route: showRoute,
        detailedAvatar: {
          avatarIcon: iconName,
          avatarEmoji: deleted ? undefined : connection.emoji,
          label: deleted ? t('deleted.content') : connection.title,
          subLabel: t('row_sublabel.content', { content_term: termSingular.toLowerCase() }),
        },
        mentionBadge: {
          iconName,
          emoji: deleted ? undefined : connection.emoji,
          label: deleted ? t('deleted.content') : connection.title,
        },
      };
    }
    case 'Step': {
      const iconName = 'list-ol';

      return {
        route: routes.consume({ slug, id: connection.id }),
        detailedAvatar: {
          avatarIcon: iconName,
          avatarEmoji: deleted ? undefined : connection.emoji,
          label: deleted ? t('deleted.content') : connection.title,
          subLabel: t('row_sublabel.content', { content_term: stepTerm.toLowerCase() }),
        },
        mentionBadge: {
          iconName,
          emoji: deleted ? undefined : connection.emoji,
          label: deleted ? t('deleted.content') : connection.title,
        },
      };
    }
    case 'Group': {
      const iconName = 'users';

      return {
        route: routes.group({ slug, id: connection.id, tab: 'overview' }),
        detailedAvatar: {
          avatarIcon: iconName,
          label: deleted ? t('deleted.group') : connection.name,
          subLabel: t('row_sublabel.group'),
        },
        mentionBadge: {
          iconName,
          label: deleted ? t('deleted.group') : connection.name,
        },
      };
    }
    case 'User': {
      const iconName: IconName = 'user';
      const detailedAvatarAvatar = deleted
        ? { avatarIcon: iconName }
        : { avatarImage: connection.avatar || undefined };
      const mentionBadgeAvatar = deleted
        ? { iconName }
        : { avatarImage: connection.avatar || undefined };

      return {
        route: routes.userProfile({ slug, id: connection.id }),
        detailedAvatar: {
          label: deleted ? t('deleted.user') : connection.name,
          subLabel: t('row_sublabel.user'),
          ...detailedAvatarAvatar,
        },
        mentionBadge: {
          label: deleted ? t('deleted.user') : connection.name,
          ...mentionBadgeAvatar,
        },
      };
    }
  }
};
