import { Edge, Node } from '@xyflow/react';
import { DependencyList, RefObject, useCallback } from 'react';
import { HotkeyCallback, Keys, Options, useHotkeys } from 'react-hotkeys-hook';

import { useFlowchart } from '../../../../contexts/FlowchartControlProvider';

type ObjectHotkeyCallback<T> = (
  obj: T,
  ...rest: Parameters<HotkeyCallback>
) => ReturnType<HotkeyCallback>;
type LeftShiftedFn<T> = T extends (a: unknown, ...rest: infer REST) => infer RET
  ? (...rest: REST) => RET
  : never;

type FlowchartHotkeys = {
  useSelectedNodeHotkeys: LeftShiftedFn<typeof useSelectedNodeHotkeys>;
  useSelectedEdgeHotkeys: LeftShiftedFn<typeof useSelectedEdgeHotkeys>;
};

const useSelectedNodeHotkeys = <T extends HTMLElement>(
  reactFlowRef: RefObject<T>,
  keys: Keys,
  callback: ObjectHotkeyCallback<Node>,
  options?: Options | DependencyList,
  dependencylist?: Options | DependencyList
) => {
  const {
    flowchartHandlers: { getNode },
  } = useFlowchart();

  const deps =
    (options instanceof Array && options) ||
    (dependencylist instanceof Array && dependencylist) ||
    [];

  const storedCallback = useCallback(callback, [callback, ...deps]);

  return useHotkeys(
    keys,
    (keyboardEvent, hotkeysEvent) => {
      const { target } = keyboardEvent;

      if (!reactFlowRef.current || !target || !(target instanceof HTMLElement)) {
        return;
      }
      if (!reactFlowRef.current.contains(target)) {
        return;
      }

      const nodeId = target.getAttribute('data-id');

      const node = nodeId ? getNode(nodeId) : null;

      if (node) {
        storedCallback(node, keyboardEvent, hotkeysEvent);
      }
    },
    options,
    dependencylist
  );
};

const useSelectedEdgeHotkeys = <T extends HTMLElement>(
  reactFlowRef: RefObject<T>,
  keys: Keys,
  callback: ObjectHotkeyCallback<Edge>,
  options?: Options | DependencyList,
  dependencylist?: Options | DependencyList
) => {
  const {
    flowchartHandlers: { edges },
  } = useFlowchart();

  const deps =
    (options instanceof Array && options) ||
    (dependencylist instanceof Array && dependencylist) ||
    [];

  const storedCallback = useCallback(callback, [callback, ...deps]);

  return useHotkeys(
    keys,
    (keyboardEvent, hotkeysEvent) => {
      const { target } = keyboardEvent;

      if (!reactFlowRef.current || !target || !(target instanceof SVGElement)) {
        return;
      }
      if (!reactFlowRef.current.contains(target)) {
        return;
      }

      const selected = edges.filter(({ selected }) => selected);
      if (selected.length === 1) {
        storedCallback(selected[0], keyboardEvent, hotkeysEvent);
      }
    },
    options,
    dependencylist
  );
};

export const useFlowchartHotkeys = <T extends HTMLElement>(
  reactFlowRef: RefObject<T>
): FlowchartHotkeys => {
  return {
    useSelectedNodeHotkeys: (...args) => useSelectedNodeHotkeys(reactFlowRef, ...args),
    useSelectedEdgeHotkeys: (...args) => useSelectedEdgeHotkeys(reactFlowRef, ...args),
  };
};
